import React from 'react';
import axios from 'axios';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Alert} from 'reactstrap';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import {NotificationContainer} from 'react-notifications';
//import Footer from 'components/Footer';
//import Tour from '../components/Tour/index';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import 'react-notifications/lib/notifications.css';
import {authLogout, authRefreshToken} from 'actions/Auth';
import config from 'config.json';


const hideHeaderFrom = [
    '/app/sign-in'
];

class App extends React.Component {
    componentDidMount() {
        this.checkTokenValidity();
        setInterval(this.checkTokenValidity, 1000 * 60 * 15);
    }

    checkTokenValidity = () => {
        const {token, history} = this.props;

        if (!token) {
            return;
        }

        axios
            .get(`${config.apiHost}/auth/check/`,
                {
                    headers: {
                        Authorization: token
                    }
                }
            )
            .then((response) => {
                if (!response.data) {
                    return;
                }

                this.props.authRefreshToken(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    this.props.authLogout();
                    history.push('/app/sign-in');
                }

            });
    }

    renderHeader() {
        const {token, routePath, navigationStyle, horizontalNavPosition} = this.props;
        if (!token || hideHeaderFrom.includes(routePath)) {
            return null;
        }

        return <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {
                    (navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                    <TopNav styleName="app-top-header"/>
            }
            <Header/>
            {
                    (navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                    <TopNav/>
            }
        </div>;
    }

    renderOnBehalfOfWarning() {
        const {companyTypeId, company, token, routePath} = this.props;

        if (!token || hideHeaderFrom.includes(routePath)) {
            return null;
        }

        if (companyTypeId !== 1) {
            return null;
        }

        return (
            <Alert className="shadow-lg" color="warning">
                Logged in on behalf of "{company}" - This message is only visible to global admins.
            </Alert>
        );
    }

    render() {
        const {token, match, drawerType} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        }
        else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }

        return (
            <div className={`app-container ${drawerStyle}`}>
                <Sidebar/>
                <NotificationContainer/>
                <div className="app-main-container">
                    {this.renderHeader()}
                    <main className="app-main-content-wrapper">
                        {this.renderOnBehalfOfWarning()}
                        <div className="app-main-content">
                            {
                                token &&
                                <Switch>
                                    <Route exact path={`${match.url}/dashboard`}
                                                 component={asyncComponent(() => import('./routes/Dashboard'))}/>
                                    <Route exact path={`${match.url}/files`}
                                                 component={asyncComponent(() => import('./routes/Files'))}/>
                                    <Route exact path={`${match.url}/files/:fileId`}
                                                 component={asyncComponent(() => import('./routes/File'))}/>
                                    <Route exact path={`${match.url}/groups`}
                                                 component={asyncComponent(() => import('./routes/Groups'))}/>
                                    <Route exact path={`${match.url}/groups/:groupId`}
                                                 component={asyncComponent(() => import('./routes/Group'))}/>
                                    <Route exact path={`${match.url}/sign-in`}
                                                 component={asyncComponent(() => import('./routes/SignIn'))}/>
                                    <Route exact path={`${match.url}/accounts`}
                                                component={asyncComponent(() => import('./routes/Accounts'))}/>
                                    <Route exact path={`${match.url}/accounts/:accountId`}
                                                component={asyncComponent(() => import('./routes/Account'))}/>
                                    <Route exact path={`${match.url}/companies`}
                                                 component={asyncComponent(() => import('./routes/Companies'))}/>
                                    <Route exact path={`${match.url}/companies/:companyId`}
                                                 component={asyncComponent(() => import('./routes/Company'))}/>

                                    <Route component={asyncComponent(() => import('components/Error404'))}/>
                                </Switch>
                            }
                            {
                                !token &&
                                <Switch>
                                    <Route component={asyncComponent(() => import('./routes/SignIn'))}/>
                                </Switch>
                            }
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({auth, router, settings}) => {
    const {drawerType, navigationStyle, horizontalNavPosition} = settings;
    const {location} = router;
    const {token, companyTypeId, company} = auth;

    return {
        token,
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        companyTypeId,
        company,
        routePath: location.pathname
    }
};

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        authLogout,
        authRefreshToken,
    }, dispatch);
};


export default withRouter(connect(mapStateToProps, mapActionsToProps)(App));