import {
        AUTH_LOGIN,
        AUTH_LOGOUT,
        AUTH_AS_COMPANY,
} from 'constants/ActionTypes';

const initialSettings = {
    name: '',
    company: '',
    token: null,
};
let previousSession = {};

const previousSessionData = window.localStorage.getItem('auth');
if (previousSessionData) {
    try {
        previousSession = JSON.parse(previousSessionData);
    } catch (err) {
        // discard session
        window.localStorage.removeItem('auth');
    }
}

const settings = (state = {...initialSettings, ...previousSession}, action) => {
    switch (action.type) {
        case AUTH_LOGIN:
            const newState = {
                ...state,
                ...action.payload
            };

            window.localStorage.setItem('auth', JSON.stringify(newState));

            return newState;

        case AUTH_LOGOUT:
            window.localStorage.removeItem('auth');

            return {
                ...initialSettings,
            };

        case AUTH_AS_COMPANY:
            const newAuthState = {
                ...state,
                ...action.payload
            };

            window.localStorage.setItem('auth', JSON.stringify(newAuthState));

            return newAuthState;

        default:
            return state;
    }
};

export default settings;
