import {
    AUTH_LOGIN,
    AUTH_REFRESH,
    AUTH_LOGOUT,
    AUTH_AS_COMPANY,
} from 'constants/ActionTypes';

export function authLogin(accountData) {
    return {
        type: AUTH_LOGIN,
        payload: {
            ...accountData,
        }
    };
}

export function authAsCompany(companyData) {
    return {
        type: AUTH_AS_COMPANY,
        payload: {
            ...companyData,
        }
    };
}

export function authRefreshToken(token) {
    return {
        type: AUTH_REFRESH,
        payload: token
    };
}

export function authLogout() {
    return {
        type: AUTH_LOGOUT
    };
}
